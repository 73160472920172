import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';

export const getPurchaseOrders = async (validated = 0) => {
	// -1, returns all unsent Pos and ‘refused’ Pos
	//  {0, returns all unsent Pos
	//  1, Sent and validated Pos
	// -2, wait for validation
	// -3, send all validated level 2 POs and not consumed yet
	const {
		data: { MyPurchaseOrders }
	} = await apollo.query({
		query: gql`
			query ($validated: Int) {
				MyPurchaseOrders(Validated: $validated) {
					id
					department
					departmentName
					description
					invoicedToProduction
					poReference
					expenseTotal
					supplierId
					comment
					supplierName
					deliveryDate
					consumed
					amount
					amountVat
					amountTotal
					userProjectId
					requestDate
					validated
					address {
						id
						state
						street
						number
						box
						lat
						lng
						city
						country
						countryCode
						countryKey
						zip
					}
				}
			}
		`,
		variables: {
			validated
		},
		fetchPolicy: 'no-cache'
	});

	return await getFormattedPurchaseOrders(MyPurchaseOrders);
};

const getValidatedText = async (validated) => {
	let value = null;

	await new Promise((resolve, reject) => {
		setTimeout(() => {
			resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
			reject(false);
		}, 100);
	}).then((data) => {
		value = data;
	});

	return value;
};

const getStatusClass = (validated) => {
	let value = 'not-submitted';

	if (validated === 1) {
		value = 'info';
	} else if (validated === 2) {
		value = 'pending';
	} else if (validated === 8) {
		value = 'validated';
	} else if (validated === 4 || validated === 16) {
		value = 'refused';
	}

	return value;
};

const getValidatedColor = (validated) => {
	let value = 'info';

	if (validated === 1) {
		value = 'primary';
	} else if (validated === 2) {
		value = 'warning';
	} else if (validated === 8) {
		value = 'success';
	} else if (validated === 4 || validated === 16) {
		value = 'danger';
	}

	return value;
};

const getFormattedPurchaseOrders = async (pos) => {
	let newpPos = [];

	for (let po of pos) {
		po = {
			...po,
			validatedStatus: await getValidatedText(po.validated),
			validatedClass: getValidatedColor(po.validated),
			_rowVariant: '',
			statusClass: getStatusClass(po.validated)
		};

		newpPos.push(po);
	}

	return newpPos;
};

export const addUpdatePurchaseOrder = async (id = 0, UserId, po) => {
	try {
		const mutation = gql`
			mutation ($id: ID!, $po: PurchaseOrderInput!, $UserId: ID) {
				UpdPurchaseOrder(PurchaseOrderId: $id, UpdatedPO: $po, UserId: $UserId) {
					id
					deliveryDate
					department
					departmentName
					description
					validated
					poReference
					amount
					amountVat
					amountTotal
					userProjectId
					requestDate
					user {
						id
					}
					encoderId
					encoder {
						id
						name
						fullName
						firstName
					}
				}
			}
		`;
		const {
			data: { UpdPurchaseOrder }
		} = await apollo.mutate({
			mutation,
			variables: {
				id: parseInt(id, 10),
				UserId: parseInt(UserId, 10),
				po
			},
			fetchPolicy: 'no-cache'
		});

		return UpdPurchaseOrder;
	} catch (e) {
		console.error({ e });
	}
};

export const getVat = async (departmentId, categoryId) => {
	const {
		data: { GetVatFromBudgetDetail }
	} = await apollo.query({
		query: gql`
			query ($departmentId: ID!, $categoryId: ID!) {
				GetVatFromBudgetDetail(Department: $departmentId, Category: $categoryId)
			}
		`,
		variables: {
			departmentId: parseInt(departmentId, 10),
			categoryId: parseInt(categoryId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return GetVatFromBudgetDetail;
};
