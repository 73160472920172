<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		no-close-on-backdrop
		:hide-header-close="$screen.width < 576"
		dialog-class="modal-to-screen"
		:hide-backdrop="$screen.width < 576"
		:hide-header="$screen.width < 576"
		id="modal-to-screen"
		v-model="isOpen"
		:footer-class="$screen.width < 576 ? 'po-footer-modal' : ''"
		size="xl"
		ref="purchacesOrderModalRef"
		@close="handleCancelPolModal"
		@cancel="handleCancelPolModal"
		no-close-on-esc
	>
		<template #modal-header="{}">
			<div class="d-flex flex-row w-100">
				<div class="d-flex flex-row w-100">
					<h5>{{ rendTitleModal }}</h5>
				</div>
				<div class="d-flex flex-row w-100">
					<div v-if="isEditMode" class="d-flex w-80 align-items-center justify-content-end w-100">
						<span v-if="!isFilmSingle" class="border-encoded p-1">
							{{ FormMSG(257, 'Encoded by:') }} <b>{{ encoder(purchaceOrder) }}</b>
						</span>
					</div>
					<!-- <div :class="isEditMode ? 'w-20' : 'w-100'">
						<button class="btn-close" type="button" @click="close()">×</button>
					</div> -->
				</div>
			</div>
		</template>
		<div class="card card-border-blue-light px-4 pt-3 pb-5" :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" v-if="purchaceOrder">
			<div class="mb-2 mt-2" v-if="$screen.width <= 576" style="display: flex">
				<i style="font-size: 17px" class="fa fa-play" aria-hidden="true"></i> &nbsp;
				<h2>{{ FormMSG(27700, 'Attach Quote') }}</h2>
			</div>
			<div>
				<b-alert :show="setallowPoEntryOutsideContract === 0 && showAlert ? true : false" variant="warning">
					<h4 class="alert-heading">{{ FormMSG(252, 'Warning') }}</h4>
					<div class="d-flex justify-content-start align-items-center w-100" style="gap: 8px">
						<div>
							<component :is="getLucideIcon(ICONS.ALERT_TRIANGLE.name)" :color="ICONS.ALERT_TRIANGLE.color" :size="40" />
						</div>
						<div>{{ messageAllowPoEntryOutsideContract }}</div>
					</div>
				</b-alert>
				<b-alert :show="setallowPoEntryOutsideContract === 1 && showAlert ? true : false" variant="warning">
					<h4 class="alert-heading">{{ FormMSG(252, 'Warning') }}</h4>
					<div class="d-flex flex-row justify-content-start align-items-center w-100" style="gap: 8px">
						<div>
							<component :is="getLucideIcon(ICONS.ALERT_TRIANGLE.name)" :color="ICONS.ALERT_TRIANGLE.color" :size="40" />
						</div>
						<div>{{ messageAllowPoEntryOutsideContract }}</div>
					</div>
				</b-alert>
				<b-modal v-model="showPopUp" size="xs" title="Hors contract" no-close-on-backdrop>
					<b-container fluid>
						<div class="d-flex flex-row justify-content-start align-items-center w-100" style="gap: 8px">
							<div>
								<component :is="getLucideIcon(ICONS.ALERT_TRIANGLE.name)" :color="ICONS.ALERT_TRIANGLE.color" :size="40" />
							</div>
							<div>{{ messageAllowPoEntryOutsideContract }}</div>
						</div>
					</b-container>
					<template #modal-footer>
						<b-button variant="primary" size="sm" class="float-right" @click="hideModal"> {{ FormMSG(255, 'No') }} </b-button>
						<b-button variant="primary" size="sm" class="float-right" @click="(e) => addOutsideContract(e)">
							{{ FormMSG(256, 'Yes') }}
						</b-button>
					</template>
				</b-modal>
			</div>
			<div v-if="isEditMode">
				<b-alert v-if="isRefusedPo" variant="danger" show>
					<b>{{ FormMSG(987897, 'Refused for') }}:</b><br />
					{{ editData.comment }}
				</b-alert>
			</div>
			<b-form-group :label="FormMSG(1, 'Reference')" label-for="po-saving-ref">
				<b-input-group>
					<b-form-input v-model="purchaceOrder.poReference" id="po-saving-ref" disabled />
					<b-input-group-append>
						<b-input-group-text>
							<component :is="getLucideIcon('Ticket')" color="#00A09C" :size="20" :stroke-width="2.25" />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
			<b-form-group :label="FormMSG(72, 'Select Person')" label-for="new-expense_ticket-ref" v-if="useComponent">
				<b-form-select size="md" v-model="selectedUser" :options="userList" value-field="value" text-field="message"></b-form-select>
			</b-form-group>
			<CapturesPackage
				multiple
				can-upload-pdf
				preview-mode="button"
				parent-type="purchase_order"
				parent-sub-type="po"
				:parent-id="+purchaceOrder.id"
				:pictures.sync="rendImagesListForPreviewer"
				:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
				@change="handleImgsTaken"
			/>
			<div class="mb-2 mt-3" v-if="$screen.width <= 576" style="display: flex">
				<i style="font-size: 17px" class="fa fa-play" aria-hidden="true"></i> &nbsp;
				<h2>{{ FormMSG(27701, 'General Information') }}</h2>
			</div>
			<div class="bootstrap-date-selection mt-3">
				<label for="po_date">{{ FormMSG(545541, 'Delivery date') }}</label>
				<!-- TODO: set default date to today -->
				<b-form-datepicker id="po_date" :disabled="!isEditable" v-model="purchaceOrder.deliveryDate" :locale="currentLang" />
			</div>
			<b-form-group :label="FormMSG(3, 'Description')" label-for="po_description">
				<b-form-textarea
					id="po_description"
					v-model="purchaceOrder.description"
					:placeholder="FormMSG(545542, 'Po\'s description...')"
					rows="3"
					:disabled="!isEditable"
					required
				/>
			</b-form-group>
			<b-form-group label-for="po_amount">
				<b-form-checkbox class="pj-cb pb-1" id="po_amount" v-model="purchaceOrder.invoicedToProduction" :disabled="!isEditable">
					{{ FormMSG(11, 'Invoiced to production') }}
				</b-form-checkbox>
			</b-form-group>
			<div>
				<SupplierSelector
					v-if="isEditable"
					:title="`${!isSupplierUpdate ? FormMSG(250, 'Add supplier details') : FormMSG(251, 'Update supplier details')}`"
					ref="supplier_selector_normal"
					:label="FormMSG(5, 'Name of a supplier')"
					:addlabel="FormMSG(5446, 'Add')"
					:update-label="FormMSG(10, 'Update')"
					:placeholder="FormMSG(545543, 'Filter by name')"
					:supplier-id="supplierId"
					:disabled="!isEditable"
					@change="handleSupplierChange"
					@supplier:unselected="onSupplierUnselected"
					use-new-design
					version="1.0"
				/>
				<b-form-group v-else :label="FormMSG(6, 'Name of a supplier')">
					<b-form-input :value="purchaceOrder.supplierName" disabled />
				</b-form-group>
			</div>
			<div class="mt-3">
				<DepartementSelection
					v-if="isEditable"
					hide-category
					:is-new="isNew"
					:disabled="!isEditable"
					:edit-data="editData"
					:department="purchaceOrder.department"
					remove-dep-zero
					@change="handleChangeDepartment"
				/>
				<b-form-group v-else :label="FormMSG(2, 'Department\'s name')">
					<b-form-input :value="purchaceOrder.departmentName" disabled />
				</b-form-group>
			</div>
			<div class="mb-2 mt-2" v-if="$screen.width <= 576" style="display: flex">
				<i style="font-size: 17px" class="fa fa-play" aria-hidden="true"></i> &nbsp;
				<h2>{{ FormMSG(277, 'PO summary') }}</h2>
			</div>
			<b-row>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group :label="FormMSG(4, 'Total amount')" label-for="po_amount">
						<b-input-group>
							<b-form-input
								v-model="purchaseOrderTotalAmount"
								type="number"
								placeholder="0.00"
								step="0.01"
								:disabled="!purchaceOrder.notDetailed"
							/>
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group :label="FormMSG(12, 'Total VAT')">
						<b-input-group>
							<b-form-input v-model="purchaseOrderTotalVat" type="number" placeholder="0.00" step="0.01" :disabled="!purchaceOrder.notDetailed" />
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
				<b-col sm="12" md="4" lg="4" xl="4">
					<b-form-group :label="FormMSG(425, 'Total amount (tax incl.)')">
						<b-input-group>
							<b-form-input v-model="pOTotalAmountTTC" disabled />
							<b-input-group-append>
								<b-input-group-text>
									<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
								</b-input-group-text>
							</b-input-group-append>
						</b-input-group>
					</b-form-group>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-form-checkbox
						class="pj-cb pb-1"
						v-model="purchaceOrder.notDetailed"
						:value="true"
						:unchecked-value="false"
						:disabled="poItems.length > 0"
					>
						{{ FormMSG(841, 'PO not detailed') }}
					</b-form-checkbox>
				</b-col>
			</b-row>
			<b-collapse :visible="purchaceOrder.notDetailed === true">
				<div class="mt-3">
					<department-selector
						hide-department
						:is-new="false"
						:department="purchaceOrder.department"
						:category="purchaceOrder.itemCategory"
						:edit-data="purchaceOrder"
						:is-submitted="submitDepartmentSelector"
						@change="handleChangeDepartmentSelector"
						@department:selector:invalid="isInvalidateFields"
						for-po
					/>
				</div>
			</b-collapse>
			<b-collapse :visible="purchaceOrder.notDetailed === false">
				<div v-if="purchaceOrder.notDetailed === false" class="mt-3" :class="{ 'card card-border-blue-light px-3': $screen.width > 576 }">
					<div class="sub-title-layout pb-3" style="font-size: 18px">
						{{ FormMSG(7, "PO's items") }}
					</div>
					<div v-if="$screen.width > 576">
						<b-table
							ref="transportsTable"
							selectable
							bordered
							small
							responsive="sm"
							sticky-header="700px"
							head-variant="dark"
							style="text-align: left"
							:items="poItems"
							:fields="poItemFields"
							hover
							selected-variant="primary"
							select-mode="single"
							:empty-text="FormMSG(568, 'No PO\'s items')"
							show-empty
						>
							<template #cell(action)="data">
								<b-button
									v-if="isEditable"
									variant="primary"
									size="sm"
									@click="handleUpdatePoItem(data.item)"
									class="btn bg-transparent border-0 px-2"
								>
									<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" :stroke-width="2.25" />
								</b-button>
								<b-button
									v-if="isEditable && poItems.length > 1"
									variant="danger"
									size="sm"
									@click="handleDeletePoItem(data.item)"
									class="btn bg-transparent border-0"
								>
									<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" :stroke-width="2.25" />
								</b-button>
							</template>
						</b-table>
					</div>
					<div v-if="$screen.width <= 576">
						<card-list-builder
							:use-new-version="true"
							:items="poItems"
							:fields="poMobileFields"
							:header-fields="poHeaderMobileFields"
							key-for-title="description"
							:toggle-mode="false"
						>
							<template slot="action" slot-scope="data">
								<b-button
									v-if="isEditable"
									variant="primary"
									size="sm"
									@click="handleUpdatePoItem(data.item)"
									class="btn bg-transparent border-0 px-2"
								>
									<component :is="getLucideIcon(ICONS.EDIT.name)" :color="ICONS.EDIT.color" :size="20" :stroke-width="2.25" />
								</b-button>
								<b-button
									v-if="isEditable"
									variant="danger"
									size="sm"
									@click="handleDeletePoItem(data.item)"
									class="btn bg-transparent border-0"
								>
									<component :is="getLucideIcon(ICONS.TRASH.name)" :color="ICONS.TRASH.color" :size="20" :stroke-width="2.25" />
								</b-button>
							</template>
						</card-list-builder>
					</div>
					<div class="w-100 d-flex justify-content-center">
						<b-button
							v-if="isEditable"
							:disabled="!saveActivePoHorsContract"
							:class="`${$screen.width <= 576 ? 'w-100' : 'w-25'}`"
							block
							variant="outline-secondary"
							@click="toggleItemModal"
						>
							{{ FormMSG(9, 'Add items') }}
						</b-button>
					</div>
				</div>
			</b-collapse>
			<div
				class="text-right"
				v-if="
					editData &&
					purchaceOrder.validated !== VALIDATION_TYPE.NOT_SUBMITTED &&
					purchaceOrder.validated !== VALIDATION_TYPE.REFUSED_LEVEL_1 &&
					purchaceOrder.validated !== VALIDATION_TYPE.REFUSED_LEVEL_2
				"
			>
				<div v-if="$screen.width >= 992" class="d-flex justify-content-end align-items-center">
					<b-button size="md" @click="downloadXls" variant="outline-primary" class="mr-3 d-flex align-items-center">
						<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" class="mr-2" />
						<div>
							{{ FormMSG(222, 'Download this Purchase Order') }}
						</div>
					</b-button>
					<b-button size="md" variant="outline-primary" @click="sendXlsByEmail" class="d-flex align-items-center" :disabled="isLoadingSendMail">
						<b-spinner v-show="isLoadingSendMail" small class="mr-2"></b-spinner>
						<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" class="mr-2" v-if="!isLoadingSendMail" />
						<div>{{ FormMSG(500, 'Send me this purchace order by Email') }}</div>
					</b-button>
				</div>
				<div v-if="$screen.width < 992" class="d-flex mt-3 pb-3 justify-content-end align-items-center">
					<b-button size="md" variant="primary" class="mr-3 d-flex justify-content-center" id="btn-download" @click="downloadXls">
						<component :is="getLucideIcon(ICONS.DOWNLOAD.name)" :size="20" :stroke-width="2.25" />
					</b-button>
					<b-tooltip target="btn-download" placement="top">
						{{ FormMSG(222, 'Download this Purchase Order') }}
					</b-tooltip>
					<b-button size="md" variant="primary" id="btn-envelope-closed" class="d-flex justify-content-center" @click="sendXlsByEmail">
						<component :is="getLucideIcon(ICONS.SEND.name)" :size="20" :stroke-width="2.25" />
					</b-button>
					<b-tooltip target="btn-envelope-closed" placement="top">
						{{ FormMSG(500, 'Send me this purchace order by Email') }}
					</b-tooltip>
				</div>
			</div>
			<POItemModal
				v-if="currentPoId"
				v-model="isItemModalOpen"
				:edit-data="currentPOSItem"
				:current-po-id="currentPoId"
				:department="purchaceOrder.department"
				@po-item:submitted="handleTotalAmountChange"
			/>
			<b-modal
				header-class="header-class-modal-doc-package"
				:title="FormMSG(21211, 'Success!')"
				class="modal-success"
				v-model="successModal"
				@ok="successModal = false"
				ok-variant="success"
				ok-only
			>
				{{ FormMSG(601, 'The purchace order has been sent to your Email address') }}
			</b-modal>
			<script-export-modal
				v-model="isScriptPoReportOpen"
				:items="scripts"
				:custom="customReport"
				export-type="po"
				@script-export-modal:loading="handleMailSent"
				@script-export-modal:closed="poRerpotOnClosed"
			/>
		</div>
		<template #modal-footer="{ cancel }">
			<!-- Emulate built in modal footer ok and cancel button actions -->
			<b-button
				:class="$screen.width < 576 ? 'w-100' : ''"
				@click="(e) => submitPurchaseOrder(e)"
				variant="success"
				:disabled="!isPoDescriptionValide || saveActivePoHorsContract === false || (useComponent && selectedUser === 0)"
				v-if="isEditable"
			>
				{{ FormMSG(9909, 'Save') }}
			</b-button>
			<b-button :class="$screen.width < 576 ? 'w-100' : ''" @click="cancel" variant="light" v-if="!isOnRemoved">
				{{ FormMSG(998, 'Close') }}
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isNil, omit, formatFileName } from '@/shared/utils';
import { deletePoItem } from '@/cruds/po-item.crud';
import languageMessages from '@/mixins/languageMessages';
import DepartementSelection from '@/components/DepartmentsSelection';
import SupplierSelector from '@/components/SupplierSelector';
import { addUpdatePurchaseOrder, getVat } from '@/cruds/purchase-order.crud';
import { rendCurrency } from '~helpers';
import { store } from '@/store';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import { getScripts } from '@/cruds/script.crud';
import globalMixin from '@/mixins/global.mixin';
import { purchaseOrderReport } from '@/cruds/export.crud';
import { getUsers } from '@/cruds/users.crud';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import moment from 'moment';
import CapturesPackage from '@/components/Packages/Captures/components/Main';
import { getFileExtension } from '@/shared/helpers';
import CurrencySvg from '@/components/Svg/Currency';
import { Play } from 'lucide-vue';
import { FILENAME_REPORT, OUTPUT_FORMAT_EXPORT, VALIDATION_TYPE } from '@/shared/constants';
import _ from 'lodash';

// const todayDate = new Date().toJSON().slice(0, 10);
const extraDate = 'T00:00:00Z';

export default {
	name: 'PoServiceComponentModal',
	mixins: [languageMessages, globalMixin, isSingleProjectMixin],
	components: {
		DepartementSelection,
		SupplierSelector,
		POItemModal: () => import('./Item'),
		ScriptExportModal,
		CapturesPackage,
		CurrencySvg,
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		Play
	},
	props: {
		useComponent: {
			type: Boolean,
			required: false,
			default: false
		},
		value: {
			type: Boolean,
			default: false
		},
		editData: {
			type: Object,
			required: false,
			default: null
		},
		forScreenValidation: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			isNew: false,
			selectedUser: 0,
			fileDownloadLink: null,
			warning: '',
			isItemModalOpen: false,
			successModal: false,
			currentPoId: null,
			currentPOSItem: null,
			purchaseOrderTotalAmount: 0,
			purchaseOrderTotalVat: 0,
			currentVat: 0,
			isOnRemoved: false,
			isSupplierUpdate: false,
			userList: [],
			isScriptPoReportOpen: false,
			poTypeId: 8,
			scripts: [],
			isLoadingSendMail: false,
			customReport: null,
			isNoContract: false,
			isActiveContract: false,
			messageNoContract: {},
			allowPoEntryOutsideContract: 0,
			messageAllowPoEntryOutsideContract: '',
			showAlert: false,
			showPopUp: false,
			addDataNoContract: false,
			addData: 'addData',
			addDirectly: 'addDirectly',
			dateSelected: moment(Date.now()).format('YYYY-MM-DD') + 'T00:00:00Z',
			contractPoActive: {
				showAlert: false,
				allowPoEntryOutsideContract: 0,
				messageAllowPoEntryOutsideContract: '',
				contractActive: false,
				showPopUp: false
			},
			isOpen: false,
			saveActivePoHorsContract: false,
			poNotDetailed: false,
			poItemCategory: 0,
			submitDepartmentSelector: false,
			isInvalid: false,
			firstLoad: true,
			VALIDATION_TYPE
		};
	},
	computed: {
		...mapGetters({
			currentPOItem: 'purchaces/currentPOItem',
			poItems: 'po-item/poItems'
		}),
		poMobileFields() {
			const keys = ['id', 'total'];
			return this.poItemFields.filter((option) => !keys.includes(option.key));
		},
		poHeaderMobileFields() {
			const keys = ['description', 'total'];
			return this.poItemFields.filter((t) => keys.includes(t.key));
		},
		poItemFields() {
			return [
				{
					key: 'id',
					label: this.FormMSG(781, 'ID'),
					sortable: true,
					formatter: (value) => `#${value}`
				},
				{
					key: 'description',
					label: this.FormMSG(782, 'Description')
				},
				{
					key: 'categoryValue',
					label: this.FormMSG(783, 'Category'),
					formatter: (value, key, item) => {
						return `${item.categoryCostCenter} - ${item.categoryName}`;
					}
				},
				{
					key: 'price',
					label: this.FormMSG(784, 'Unit price (tax excl.)'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					class: 'text-right'
				},
				{
					key: 'priceVat',
					label: this.FormMSG(785, 'Unit VAT'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					class: 'text-right'
				},
				{
					key: 'quantity',
					label: this.FormMSG(786, 'Quantity'),
					class: 'text-center'
				},
				{
					key: 'total',
					label: this.FormMSG(787, 'Total amount (tax incl.)'),
					formatter: (value) => {
						return rendCurrency(value);
					},
					class: 'text-right'
				},
				{
					key: 'action',
					class: 'text-center',
					isSlot: true
				}
			];
		},
		pOTotalAmountTTC() {
			return parseFloat(this.purchaseOrderTotalAmount) + parseFloat(this.purchaseOrderTotalVat);
		},

		/**
		 * @return {Array}
		 */
		rendImagesListForPreviewer() {
			/** @type {Array} imgs */
			const imgs = this.purchaceOrder.images;

			if (isNil(imgs) || imgs.length === 0) return [];
			return imgs.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));
		},
		purchaceOrder: {
			get() {
				return this.currentPOItem;
			},
			set(po) {
				this.setCurrentPOItem(po);
			}
		},
		isEditable() {
			if (!this.forScreenValidation) {
				const ed = this.editData;
				if (isNil(ed)) return true;
				return (
					ed.validated === VALIDATION_TYPE.NOT_SUBMITTED ||
					ed.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 ||
					ed.validated === VALIDATION_TYPE.REFUSED_LEVEL_2
				);
			}

			return true;
		},
		isEditMode() {
			return !isNil(this.editData);
		},
		rendTitleModal() {
			const _t = this.FormMSG;
			const e = !isNil(this.editData);
			const t = e ? _t(421, 'Edit') : _t(422, 'Add new');
			return `${t} ${_t(423, 'purchase order')}`;
		},
		/**
		 * @return {Boolean}
		 */
		isRefusedPo() {
			if (!this.isEditable) return false;
			//  const status = this.editData.validatedStatus;
			const status = parseInt(this.editData.validated);
			return status == 4 || status == 16;
		},
		/**
		 * @return {Boolean}
		 */
		isPoDescriptionValide() {
			const po = this.purchaceOrder;
			if (isNil(po) || isNil(po.description)) return false;
			return po.description !== '' && this.purchaseOrderTotalAmount !== 0;
		},
		currencySymbol() {
			return store.state.myProfile.currentSymbol;
		},
		supplierId() {
			// current supplier id of edit data
			this.isSupplierUpdate = true;

			if (isNil(this.purchaceOrder) || parseInt(this.purchaceOrder.supplierId, 10) === 0) {
				this.isSupplierUpdate = false;

				return 0;
			}

			return this.purchaceOrder.supplierId;
		},
		setallowPoEntryOutsideContract() {
			return this.allowPoEntryOutsideContract;
		}
		// setAllowPoEntryOutsideContract() {
		// 	return store.getCurrentProjectConfig().allowPOEntryOutsideContract;
		// }
	},
	watch: {
		editData: {
			handler(val) {
				if (isNil(val)) {
					this.isNew = true;
				} else {
					this.isNew = false;
				}
			},
			immediate: true,
			deep: true
		},
		/**
		 * @param {Boolean} status
		 */
		value: {
			handler(val) {
				this.isOpen = val;
				if (this.isOpen) {
					this.handleDateForValidContract(this.dateSelected);
					return val;
				} else {
					this.contractPoActive = {
						showAlert: false,
						allowPoEntryOutsideContract: 0,
						messageAllowPoEntryOutsideContract: '',
						contractActive: false,
						showPopUp: false
					};
					this.messageAllowPoEntryOutsideContract = this.contractPoActive.messageAllowPoEntryOutsideContract;
					this.showAlert = this.contractPoActive.showAlert;
					return val;
				}
			},
			immediate: true,
			deep: true
		},
		'purchaceOrder.notDetailed': {
			handler(val) {
				if (val === false && this.purchaseOrderTotalAmount !== 0 && this.isNew) {
					this.purchaseOrderTotalAmount = 0;
				}
				if (val === true && this.poItems.length > 0 && this.isNew) {
					this.poItems = [];
					this.purchaseOrderTotalAmount = 0;
				}
			},
			immediate: true,
			deep: true
		},
		'purchaceOrder.deliveryDate': {
			handler(val) {
				if (val) {
					this.handleDateForValidContract(val);
				}
			},
			immediate: true,
			deep: true
		},
		isOpen: {
			async handler(val) {
				if (val) {
					await this.initModalUser();
					await this.initModal();
					return val;
				} else {
					this.isOnRemoved = false;
					return val;
				}
			},
			immediate: true,
			deep: true
		}
	},
	mounted() {
		/**
		 * @BusEvent
		 * @param {Object} payload
		 * @return {Promise}
		 */
		this.$bus.$on('filepreviewer:image-deleted-from-parent', (payload) => {
			if (isNil(this.purchaceOrder)) return;
			this.purchaceOrder.images = payload.refreshedImages || [];
		});
	},
	methods: {
		...mapActions({
			setNewPurchaseOrder: 'purchaces/newPurchaseOrder',
			setCurrentPOItem: 'purchaces/setCurrentPOItem',
			// getPurchaseOrder: "purchaces/getPurchaseOrder",
			// updatePurchaseOrder: "purchaces/updatePurchaseOrder",
			destroySelectedPO: 'purchaces/destroySelectedPO',
			deletePurchaseOrder: 'purchaces/deletePurchaseOrder',
			getPoItems: 'po-item/getPoItems'
		}),
		handleDateForValidContract(selectedDate) {
			let data = this.checkDateValidContract(selectedDate);
			const { allowPoEntryOutsideContract, contractActive, messageAllowPoEntryOutsideContract, showAlert, showPopUp } = data;
			this.allowPoEntryOutsideContract = allowPoEntryOutsideContract;
			this.messageAllowPoEntryOutsideContract = messageAllowPoEntryOutsideContract;
			this.showAlert = showAlert;
			this.isActiveContract = contractActive;
			this.showPopUp = showPopUp;
			if (this.allowPoEntryOutsideContract === 0 && this.showAlert === false && this.showPopUp === false) {
				this.saveActivePoHorsContract = true;
			} else {
				this.saveActivePoHorsContract = false;
			}
		},
		isInvalidateFields(payload) {
			this.isInvalid = payload;
		},
		handleChangeDepartmentSelector(payload) {
			if (isNil(payload.category)) {
				if (!isNil(this.editData) && this.editData.itemCategory > 0) {
					this.purchaceOrder.itemCategory = this.editData.itemCategory;
				} else {
					this.purchaceOrder.itemCategory = 0;
				}
			} else {
				this.purchaceOrder.itemCategory = payload.category;
			}
		},
		handleImgsTaken(images) {
			this.purchaceOrder.images = images;
			this.$emit('po-service:images-change', this.purchaceOrder);
		},
		encoder(data) {
			if (data.encoder !== null) {
				return `${data.encoder.firstName} ${data.encoder.name}`;
			}
		},
		/**
		 * @param {Object} obj
		 */
		setPurchaseOrder(obj) {
			this.purchaceOrder = {
				...this.purchaceOrder,
				...obj
			};
		},
		/**
		 * @param {Number} value
		 */
		async handleTotalAmountChange(payload) {
			if (isNil(payload)) return;

			await this.getPoItems(this.currentPOItem.id).then(() => {
				const { amount, vat } = this.totalAmountAndVat(this.poItems);

				this.purchaseOrderTotalAmount = amount;
				this.purchaseOrderTotalVat = vat;
				this.isOnRemoved = false;
			});
		},
		hideModal() {
			this.showPopUp = false;
			this.addDataNoContract = false;
			this.contractPoActive = {
				showAlert: false,
				allowPoEntryOutsideContract: 0,
				messageAllowPoEntryOutsideContract: '',
				contractActive: false,
				showPopUp: false
			};
			this.messageAllowPoEntryOutsideContract = this.contractPoActive.messageAllowPoEntryOutsideContract;
			this.showAlert = this.contractPoActive.showAlert;
			this.handleCancelPolModal();
		},
		addOutsideContract(e) {
			this.saveActivePoHorsContract = true;
			this.messageAllowPoEntryOutsideContract = '';
			this.showAlert = false;
			this.showPopUp = false;
		},
		async submitPurchaseOrder(e) {
			e.preventDefault();
			this.submitDepartmentSelector = true;

			if (this.purchaceOrder.notDetailed === true && this.isInvalid === true) {
				return;
			}
			if (this.saveActivePoHorsContract) {
				this.addUpdatePoData();
				this.contractPoActive = {
					showAlert: false,
					allowPoEntryOutsideContract: 0,
					messageAllowPoEntryOutsideContract: '',
					contractActive: false,
					showPopUp: false
				};
				this.messageAllowPoEntryOutsideContract = this.contractPoActive.messageAllowPoEntryOutsideContract;
				this.showAlert = this.contractPoActive.showAlert;
				this.createToastForMobile(this.FormMSG(154, 'Success'), this.FormMSG(34, 'Po create/update with success.'));
			}

			this.submitDepartmentSelector = false;
		},
		async addUpdatePoData() {
			if (this.forScreenValidation) {
				let result = await this.addUpdatePO();
				return result;
			} else {
				if (this.purchaceOrder.validated !== VALIDATION_TYPE.SUBMITTED) {
					let result = await this.addUpdatePO();
					return result;
				}
			}
		},
		async initModalUser() {
			let forPo = true;
			await getUsers({ myProject: true }, 0, -1, 0, 0, '', '', forPo).then((users) => {
				users.forEach((user) => {
					user.value = user.id;
					user.message = user.fullName;
				});
				this.userList = users;
			});
			let isResult = this.userList.find((item) => +item.value === +this.selectedUser);

			if (isResult === undefined && !this.selectedUser) {
				this.selectedUser = 0;
			}
			if (this.useComponent) {
				this.userList = this.userList.filter((item) => +item.value !== +store.state.myProfile.id);
			}
			this.userList = [
				{
					value: 0,
					message: 'Select ...'
				},
				...this.userList
			];
		},
		async sendXlsByEmail() {
			this.isLoadingSendMail = true;
			this.warning = '';
			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.poTypeId, true);
			const id = +this.purchaceOrder.id;

			if (this.scripts.length === 0) {
				await this.managePoReport(id, { id: 0 }, true).then(() => {
					this.createToastForMobile(this.FormMSG(154, 'Success'), this.FormMSG(155, 'The document has been sent to your email address.'));
				});
			} else if (this.scripts.length === 1) {
				await this.managePoReport(id, { id: this.scripts[0].id }, true).then(() => {
					this.createToastForMobile(this.FormMSG(154, 'Success'), this.FormMSG(155, 'The document has been sent to your email address.'));
				});
			} else {
				this.isScriptPoReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: true
				};
			}
		},
		async downloadXls() {
			this.warning = '';
			this.scripts = await getScripts(store.getCurrentProjectConfig().licenseID, store.getCurrentProjectConfig().id, this.poTypeId, true);
			const id = +this.purchaceOrder.id;

			if (this.scripts.length === 0) {
				await this.managePoReport(id);
			} else if (this.scripts.length === 1) {
				await this.managePoReport(id, {
					id: this.scripts[0].id,
					fileName: this.scripts[0].customExportFileName,
					ext: this.getExtensionScript(
						OUTPUT_FORMAT_EXPORT.at(this.scripts[0].outputFileFormat),
						this.scripts[0].customExtension,
						getFileExtension(this.scripts[0].fileName)
					)
				});
			} else {
				this.isScriptPoReportOpen = true;
				this.customReport = {
					subjectId: id,
					sendEmail: false
				};
			}
		},
		async addUpdatePO() {
			const omits = [
				'id',
				'__typename',
				// "validated",
				'departmentName'
			];
			// this.purchaceOrder.amount = this.purchaseOrderTotalAmount;

			this.purchaceOrder = {
				...this.purchaceOrder,
				amount: parseFloat(this.purchaseOrderTotalAmount),
				amountVat: parseFloat(this.purchaseOrderTotalVat),
				amountTotal: parseFloat(this.purchaseOrderTotalAmount) + parseFloat(this.purchaseOrderTotalVat)
			};

			const po = omit(omits, this.purchaceOrder);

			if (po.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 || po.validated === VALIDATION_TYPE.REFUSED_LEVEL_2) {
				po.validated = VALIDATION_TYPE.NOT_SUBMITTED;
			}
			await addUpdatePurchaseOrder(this.purchaceOrder.id, this.selectedUser, {
				...po,
				deliveryDate: this.rendDate(po.deliveryDate)
			}).then((result) => {
				if (result) {
					// this.isOpen = false;
					this.$emit('submitted', result);
				}
			});
		},
		async handleCancelPolModal() {
			this.contractPoActive = {
				showAlert: false,
				allowPoEntryOutsideContract: 0,
				messageAllowPoEntryOutsideContract: '',
				contractActive: false,
				showPopUp: false
			};
			this.messageAllowPoEntryOutsideContract = this.contractPoActive.messageAllowPoEntryOutsideContract;
			this.showAlert = this.contractPoActive.showAlert;
			this.$emit('handleActivePoModalContract', false);
			if (this.isEditMode) {
				this.$emit('closed');
				return;
			}
			const po = this.purchaceOrder;
			this.firstLoad = false;
			if (isNil(po)) {
				this.$emit('closed');
				return;
			}
			// this.isOpen = false;
			await this.deletePurchaseOrder(po.id);
			this.$emit('closed');
		},
		/**
		 * @param {Object} supplier
		 */
		handleSupplierChange(supplier) {
			this.isSupplierUpdate = false;

			if (isNil(supplier)) return;

			this.isSupplierUpdate = true;
			const { id, supplierName } = supplier;
			this.setPurchaseOrder({
				supplierId: parseInt(id),
				supplierName: supplierName
			});
		},
		/**
		 * @param {Object} po - po.departmentID
		 */
		async handleChangeDepartment({ department }) {
			this.setPurchaseOrder({ department });
		},
		async initModal() {
			if (this.isEditMode) {
				await this.setEdititon();
			} else {
				await this.setNewPO();
			}
		},
		async setEdititon() {
			const { isEditMode, isEditable, editData } = this;
			if (!isEditMode && isEditable) return;
			this.setPurchaseOrder(editData);
			this.currentPoId = editData.id;
			await this.getPoItems(editData.id);
			this.purchaseOrderTotalAmount = editData.amount;
			this.purchaseOrderTotalVat = editData.amountVat;
			this.selectedUser = editData.user.id;
		},
		async setNewPO() {
			if (this.isEditMode) return;
			this.$store.dispatch('po-item/cleanPOItems');
			await this.destroySelectedPO();
			await this.setNewPurchaseOrder();
			// this.handleTotalAmountChange(0);
			// this.getPoItems(this.purchaceOrder.id);
			this.userList = [...this.userList];
			if (this.useComponent) {
				this.userList = this.userList.filter((item) => +item.value !== +store.state.myProfile.id);
			}
			this.selectedUser = store.state.myProfile.id;
			let isResult = this.userList.find((item) => +item.value === +this.selectedUser);
			if (isResult === undefined) {
				this.selectedUser = 0;
			}

			this.purchaseOrderTotalAmount = 0;
			this.purchaseOrderTotalVat = 0;
			this.currentPoId = this.purchaceOrder.id;
		},
		/**
		 * @param {String} date
		 * @return {String}
		 */
		rendDate(date) {
			if (date.includes('T')) return date;
			return date + extraDate;
		},
		toggleItemModal() {
			this.currentPOSItem = null;
			this.isItemModalOpen = true;
		},
		/**
		 * @param {Object} item
		 */
		handleDeletePoItem(item) {
			if (isNil(item)) return;

			const _t = this.FormMSG;
			const txt = _t(42421, "Please confirm that you want delete this po's item");

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: 'Please Confirm',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: _t(42422, 'YES'),
					cancelTitle: _t(42423, 'NO'),
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (s) => {
					if (!s) return;

					await deletePoItem({
						id: item.id,
						poid: this.currentPoId
					}).then(async () => {
						await this.getPoItems(this.currentPoId).then(() => {
							const { amount, vat } = this.totalAmountAndVat(this.poItems);

							this.purchaseOrderTotalAmount = amount;
							this.purchaseOrderTotalVat = vat;
							this.isOnRemoved = true;

							this.$emit('refresh', true);
						});
					});
				})
				.catch((err) => console.log(err));
		},
		/**
		 * @param {Object} item
		 */
		handleUpdatePoItem(item) {
			// console.log({item})
			this.currentPOSItem = item;
			this.isItemModalOpen = true;
		},
		formatAmount(amount) {
			return rendCurrency(amount);
		},
		totalAmountAndVat(items) {
			let amount = 0,
				vat = 0;

			for (const item of items) {
				amount += item.priceTotal;
				vat += item.priceVatTotal;
			}

			amount = amount.toFixed(2).replace(/(\.0{2})$/g, '');
			vat = vat.toFixed(2).replace(/(\.0{2})$/g, '');

			return {
				amount,
				vat
			};
		},
		onSupplierUnselected() {
			this.isSupplierUpdate = false;
		},
		async managePoReport(subjectId, script = { id: 0, fileName: null, ext: 'xlsx' }, sendEmail = false) {
			let fileName = this.getFileNameScript(FILENAME_REPORT.REPORT_PO, script.id, script.fileName);

			await purchaseOrderReport(
				{
					subjectId,
					sendEmail
				},
				script.id
			)
				.then((result) => {
					if (!sendEmail) {
						this.forceDownload(result, fileName + '.' + script.ext);
					}
				})
				.catch((e) => {
					console.error(e);
				})
				.finally(() => (this.isLoadingSendMail = false));
		},
		poRerpotOnClosed() {
			this.isScriptPoReportOpen = false;
		},
		handleMailSent(payload) {
			if (payload) {
				this.isLoadingSendMail = false;
			}
		},
		checkDateValidContract(date) {
			const langCurrent = this.currentLang;
			const contracts = store.state.myContracts.map((contract) => {
				const contractDate1 = new Date(contract.startDate);
				const contractDate2 = new Date(contract.endDate);
				const dateToCheck = new Date(date);
				if (dateToCheck >= contractDate1 && dateToCheck <= contractDate2) {
					return true;
				} else {
					return false;
				}
			});
			if (this.setAllowPoEntryOutsideContract) {
				//if true, allowOutsideContract = 1(on peut ajouter en dehors de son contrat)
				if (contracts.some((isValid) => isValid === true)) {
					this.allowPoEntryOutsideContract = 0;
					this.showAlert = false;
					this.messageAllowPoEntryOutsideContract = this.FormMSG(
						40,
						'You have currently no valid contract and you are not allowed to enter PO outside your contract.'
					);
					this.contractPoActive = {
						showAlert: this.showAlert,
						allowPoEntryOutsideContract: this.allowPoEntryOutsideContract,
						messageAllowPoEntryOutsideContract: this.messageAllowPoEntryOutsideContract,
						contractActive: true,
						showPopUp: false
					};
					return this.contractPoActive;
				} else {
					this.allowPoEntryOutsideContract = 1;
					this.showAlert = true;
					this.messageAllowPoEntryOutsideContract = `${this.FormMSG(
						42,
						'You have currently no valid contract. Are you sure you want to create a new PO?'
					)}`;
					this.contractPoActive = {
						showAlert: this.showAlert,
						allowPoEntryOutsideContract: this.allowPoEntryOutsideContract,
						messageAllowPoEntryOutsideContract: this.messageAllowPoEntryOutsideContract,
						contractActive: false,
						showPopUp: true
					};
					return this.contractPoActive;
				}
			} else {
				if (contracts.some((isValid) => isValid === true)) {
					this.allowPoEntryOutsideContract = 0;
					this.showAlert = false;
					this.messageAllowPoEntryOutsideContract = this.FormMSG(
						40,
						'You have currently no valid contract and you are not allowed to enter PO outside your contract.'
					);
					this.contractPoActive = {
						showAlert: this.showAlert,
						allowPoEntryOutsideContract: this.allowPoEntryOutsideContract,
						messageAllowPoEntryOutsideContract: this.messageAllowPoEntryOutsideContract,
						contractActive: true,
						showPopUp: false
					};
					return this.contractPoActive;
				}
				this.allowPoEntryOutsideContract = 0;
				this.showAlert = true;
				this.messageAllowPoEntryOutsideContract = this.FormMSG(
					40,
					'You have currently no valid contract and you are not allowed to enter PO outside your contract.'
				);
				this.contractPoActive = {
					showAlert: this.showAlert,
					allowPoEntryOutsideContract: this.allowPoEntryOutsideContract,
					messageAllowPoEntryOutsideContract: this.messageAllowPoEntryOutsideContract,
					contractActive: false,
					showPopUp: false
				};
				return this.contractPoActive;
			}
		}
	}
};
</script>
<style lang="scss">
.po-item-desc {
	width: 65%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	&.responsive {
		width: 58%;
	}
}

.btn-close {
	background-color: transparent;
	border: 0;
	float: right;
	font-size: 1.5rem;
	font-weight: 700;
	line-height: 1;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.5;
}

.border-encoded {
	border: 2px solid #cad0d7;
	border-radius: 5px;
}

#modal-add-po label,
#modal-add-po legend {
	font-size: 14px !important;
	margin-bottom: 0.5rem !important;
}
</style>
